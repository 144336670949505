import { Component, EventEmitter, OnInit, Output, Input, isDevMode } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IIpInfo } from '@lib/features/geolocation/ip.models';
import { IpService } from '@lib/features/geolocation/ip.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@UntilDestroy()
@Component({
  selector: 'app-signup-form-step1',
  templateUrl: './signup-form-step1.component.html',
  styleUrls: ['../../../components/partner-signup-core/partner-signup-core.component.scss',
    '../signup-form/signup-form.component.scss']
})
export class SignupFormStep1Component implements OnInit {
  @Output() stepSubmitted: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  private defaultValue = {
    firstName: 'John',
    lastName: 'Doe',
    title: 'Mr.',
    address1: 'address 1 right here',
    address2: 'address 2 right there',
    city: 'Sathon',
    state: 'Bangkok',
    country: 142,
    zip: '10250',
    email: 'jonh@doe.com',
    phone: '2015550123'
  };

  @Input() countriesList: {
    callingCode: string,
    code: string,
    id: number,
    name: string,
    slug: string,
    universalName: string
  }[] = [] ;

  SearchCountryField = SearchCountryField;
  selectedCountryISO: CountryISO = CountryISO.UnitedStates;
  preferredCountries: Array<string> = [
    CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Canada, CountryISO.Australia, CountryISO.France
  ];
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode = false;

  constructor(private fb: UntypedFormBuilder,  private ipService: IpService) {
    if (!isDevMode()) {
     this.defaultValue = {
        firstName: '',
        lastName: '',
        title: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '' as any,
        zip: '',
        email: '',
        phone: ''
      };
    }

    this.form = this.fb.group({
      contact_first_name: [this.defaultValue.firstName, Validators.required],
      contact_last_name: [this.defaultValue.lastName, Validators.required],
      contact_title: [this.defaultValue.title, Validators.required],
      contact_address1: [this.defaultValue.address1, [Validators.required, Validators.minLength(5)]],
      contact_address2: [this.defaultValue.address2, Validators.minLength(5)],
      contact_city: [this.defaultValue.city, Validators.required],
      contact_state: [this.defaultValue.state, Validators.required],
      contact_country: [this.defaultValue.country, Validators.required],
      contact_zip: [this.defaultValue.zip, Validators.required],
      contact_email: [this.defaultValue.email, [Validators.required, Validators.email]],
      contact_phone: [this.defaultValue.phone, [Validators.required, Validators.maxLength(19), Validators.minLength(9)]]
    });

    this.ipService.ipGeoInfo$.pipe(untilDestroyed(this)).subscribe((data: IIpInfo | null) => {
      if (data !== null) {
        this.selectedCountryISO = data?.countryCode ? data?.countryCode.toLocaleLowerCase() as any : this.selectedCountryISO;
      }
    });

  }

  ngOnInit() { }

  submit() {
    Object.keys(this.form.controls).forEach(field => {
      this.form.get(field)?.markAsTouched({ onlySelf: true });
    });

    if (!this.form.valid) {
      return;
    }
    this.form.value.contact_phone = this.form.value.contact_phone?.e164Number
      ? this.form.value.contact_phone?.e164Number : this.form.value.contact_phone;

    this.stepSubmitted.emit({ step_id: 1, form: this.form.value });
  }

}
