import { IPartnerSignUp } from '@lib/models/partner.model';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FOOTER_DISPLAY_STATE,
  HEADER_DISPLAY_STATE,
  PageLayoutService
} from '@lib/features/page-layout/page-layout.service';

@Component({
  selector: 'app-partner-signup-core',
  templateUrl: './partner-signup-core.component.html',
  styleUrls: ['./partner-signup-core.component.scss']
})
export class PartnerSignupCoreComponent implements OnInit, OnDestroy {
  @Input() clinicsCount = 0;
  @Input() countriesCount = 0;
  @Input() countriesList: {
    callingCode: string,
    code: string,
    id: number,
    name: string,
    slug: string,
    universalName: string
  }[] = [] ;
  @Input() certificatesList: any[] = [];
  @Input() siteName!: string;

  signUpForm!: IPartnerSignUp;

  partnershipFormState: PARTNERSHIP_FORM_ENUM = PARTNERSHIP_FORM_ENUM.WHY_PARTNER;

  partnershipFormEnum = PARTNERSHIP_FORM_ENUM;

  isSubmitted = false;

  constructor(
    private pageLayoutService: PageLayoutService
    ) {}

  ngOnInit() {
    this.pageLayoutService.setToggleHeader(HEADER_DISPLAY_STATE.HIDE_HEADER);
    this.pageLayoutService.setToggleFooter(FOOTER_DISPLAY_STATE.HIDE_FOOTER);
  }

  // @ts-ignore
  get isSignupForm(): boolean {
    return this.partnershipFormState === PARTNERSHIP_FORM_ENUM.SIGNUP_FORM;
  }

  changeFormState(state: PARTNERSHIP_FORM_ENUM) {
    this.partnershipFormState = state;
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.pageLayoutService.setToggleHeader(HEADER_DISPLAY_STATE.SHOW_HEADER);
    this.pageLayoutService.setToggleFooter(FOOTER_DISPLAY_STATE.SHOW_FOOTER);
  }

}

export enum PARTNERSHIP_FORM_ENUM {
  WHY_PARTNER = 'whyPartner',
  SIGNUP_FORM = 'signupForm',
  OPT_OUT_FORM = 'optOutForm',
  PARTNER_THANK_YOU = 'partnerThankYou',
  OPT_OUT_THANK_YOU = 'OptOutThankYou'
}
