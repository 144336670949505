import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PARTNERSHIP_FORM_ENUM } from '@lib/shared-core/components/partner-signup-core/partner-signup-core.component';
import { Router } from '@angular/router';
import { FOOTER_DISPLAY_STATE, PageLayoutService } from '@lib/features/page-layout/page-layout.service';

@Component({
  selector: 'app-opt-out-thank-you',
  templateUrl: './opt-out-thank-you.component.html',
  styleUrls: ['./../partner-thank-you/partner-thank-you.component.scss', './opt-out-thank-you.component.scss']
})
export class OptOutThankYouComponent implements OnInit, OnDestroy {
  @Input() siteName!: string;
  @Output() partnerFormState: EventEmitter<PARTNERSHIP_FORM_ENUM> = new EventEmitter();

  constructor(
    private route: Router,
    private pageLayoutService: PageLayoutService
  ) { }

  ngOnInit(): void {
    this.pageLayoutService.setToggleFooter(FOOTER_DISPLAY_STATE.SHOW_ONLY_SMALL_FOOTER);
  }

  goto(route?: string) {
    this.route.navigateByUrl(route ?? '/');
  }

  ngOnDestroy() {
    this.pageLayoutService.setToggleFooter(FOOTER_DISPLAY_STATE.HIDE_FOOTER);
  }


}
