import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InternalErrorComponent } from './layouts/internal-error/internal-error.component';
import { PageNotFoundComponent } from './layouts/page-not-found/page-not-found.component';
import { RebookRedirectComponent } from './modules/appointment/pages/rebook-redirect/rebook-redirect.component';
import { HomeComponent } from './modules/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'promotions', loadChildren: () => import('./modules/promotion/promotion.module').then(m => m.PromotionModule) },
  { path: 'partner', loadChildren: () => import('./modules/partner/partner.module').then(m => m.PartnerModule) },
  { path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
  { path: 'press', loadChildren: () => import('./modules/press/press.module').then(m => m.PressModule) },
  { path: 'free-quote', loadChildren: () => import('./modules/free-quote/free-quote.module').then(m => m.FreeQuoteModule) },
  { path: 'livechat', loadChildren: () => import('./modules/livechat/livechat.module').then(m => m.LivechatModule) },
  { path: 'mail', loadChildren: () => import('./modules/newsletter/newsletter.module').then(m => m.NewsletterModule) },
  { path: 'global-patients-choice', loadChildren: () => import('./modules/gpc/gpc.module').then(m => m.GpcModule) },
  { path: 'finance', loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule) },
  { path: 'about', loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule) },
  { path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule) },
  { path: 'mediaroom', redirectTo: 'media-room' },
  { path: 'media-room', loadChildren: () => import('./modules/media-room/media-room.module').then(m => m.MediaRoomModule) },
  { path: 'info', loadChildren: () => import('./modules/premium-content/premium-content.module').then(m => m.PremiumContentModule) },
  { path: 'appointments/login', redirectTo: '/book/login' },
  { path: 'appointments/rebook', component: RebookRedirectComponent},
  { path: 'book', loadChildren: () => import('./modules/appointment/appointment.module').then(m => m.AppointmentModule) },
  { path: '', loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule) },
  { path: '', loadChildren: () => import('./modules/clinic/clinic.module').then(m => m.ClinicModule) },
  { path: '', loadChildren: () => import('./modules/article/article.module').then(m => m.ArticleModule) },
  { path: '', loadChildren: () => import('./modules/seo/seo.module').then(m => m.SeoModule) },
  { path: '', loadChildren: () => import('./modules/info/info.module').then(m => m.InfoModule) },
  { path: '404', component: PageNotFoundComponent },
  { path: '500', component: InternalErrorComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: false,
    // errorHandler: //
    initialNavigation: 'enabledBlocking',
    preloadingStrategy: NoPreloading,
    onSameUrlNavigation: 'ignore',
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    urlUpdateStrategy: 'eager'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
