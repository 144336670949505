import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IClinic } from '@lib/modules/clinic/clinic.models';
import { ClinicService } from '@lib/modules/clinic/clinic.service';
import { IUserClinicReview } from '@lib/shared-core/models/review.model';
import { environment } from 'projects/dental/src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
// import { SwiperOptions } from 'swiper';

@Component({
    selector: 'app-reviews-popup',
    templateUrl: './reviews-popup.component.html',
    styleUrls: ['./reviews-popup.component.scss']
})
export class ReviewsPopupComponent implements OnInit {

    @Output()
    onClickShowMore: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    onClickWriteAReview: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    clinic!: IClinic;

    @Input()
    clinicReview!: IUserClinicReview | any;

    @Input()
    isReviewModal: boolean = false;

    config!: any;

    private _latestReviews: IUserClinicReview[] | any;
    @Input()
    set latestReviews(latestReviews: IUserClinicReview[] | any) {
        this._latestReviews = latestReviews;
    }
    get latestReviews() {
        return this._latestReviews;
    }

    summmaryReport: { label: any, value: number, percentageValue: number }[] = [];

    reviewLength: number = 0;
    cleanliness: number = 0;
    communication: number = 0;
    location: number = 0;
    value: number = 0;
    quality: number = 0;
    overall: number = 0;
    service: number = 0;
    comfort: number = 0;
    searchQuery: string = '';
    currentIndex: any = 1;
    isAllReviewsLoaded: boolean = false;

    currentReview: any;
    summmaryReportSpecific: any;
    isModalVisible:boolean = false;

    writeAReview = false;

    constructor(private clinicService: ClinicService, private route: ActivatedRoute,) { }

    ngOnInit(): void {
        const { ratings } = this.clinic;
        if (ratings) {
            this.summmaryReport = this.clinicService.mapSummaryReport(ratings);
        } else {
            this.calculateAverageStats();
        }

        this.route.queryParamMap.subscribe(q => {
          this.writeAReview = !!q.get('write_a_review');
        });

        if(this.writeAReview) {
          this.onClickWriteAReview.emit(true);
        }
    }

    onClickReviewMore(data: any) {
        if(data) {
            const { currentReview,  summaryReport } = data;
            this.currentReview = currentReview;
            this.summmaryReportSpecific = summaryReport;
            this.isModalVisible = true;
        }
    }

    swiperConfig(): any {
        return {
          updateOnWindowResize: true,
          direction: 'horizontal',
          slidesPerView: 4,
          keyboard: false,
          mousewheel: false,
          scrollbar: false,
          navigation: true,
          pagination: false,
          breakpoints: {
            120: {
              slidesPerView: 3,
              spaceBetween: 10
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 10
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 40
            }
          }
        };
    }

    openImageGallery(data: any) {
        window.open(data);
      }

    public choosenReview(review: any) {
        this.currentReview = review;
        this.isModalVisible = true;
        this.summmaryReportSpecific = this.clinicService.mapSummaryReport(review.rating);
      }

    calculateAverageStats() {
        /** Support calculation if backend has no data */
        if (this.latestReviews) {
            this.reviewLength = this.latestReviews.length;
            this.cleanliness = (this.latestReviews.reduce((acc: number, data: any) => {
                return acc + data.rating.cleanliness;
            }, 0)) / this.reviewLength;

            this.communication = (this.latestReviews.reduce((acc: number, data: any) => {
                return acc + data.rating.communication;
            }, 0)) / this.reviewLength;

            this.overall = (this.latestReviews.reduce((acc: number, data: any) => {
                return acc + data.rating.overall;
            }, 0)) / this.reviewLength;

            this.quality = (this.latestReviews.reduce((acc: number, data: any) => {
                return acc + data.rating.quality;
            }, 0)) / this.reviewLength;

            this.service = (this.latestReviews.reduce((acc: number, data: any) => {
                return acc + data.rating.service;
            }, 0)) / this.reviewLength;

            this.comfort = (this.latestReviews.reduce((acc: number, data: any) => {
                return acc + data.rating.comfort;
            }, 0)) / this.reviewLength;

            this.value = (this.latestReviews.reduce((acc: number, data: any) => {
                return acc + data.rating.value;
            }, 0)) / this.reviewLength;

            this.summmaryReport = [
                {
                    label: 'Cleanliness',
                    value: this.cleanliness,
                    percentageValue: (this.cleanliness / 5) * 100,
                },
                {
                    label: 'Communication',
                    value: this.communication,
                    percentageValue: (this.communication / 5) * 100,
                },
                {
                    label: 'Value',
                    value: this.value,
                    percentageValue: (this.value / 5) * 100,
                },
                {
                    label: 'Quality',
                    value: this.quality,
                    percentageValue: (this.quality / 5) * 100,
                },
                {
                    label: 'Overall',
                    value: this.overall,
                    percentageValue: (this.overall / 5) * 100,
                },
                {
                    label: 'Service',
                    value: this.service,
                    percentageValue: (this.service / 5) * 100,
                },
                {
                    label: 'Comfort',
                    value: this.comfort,
                    percentageValue: (this.comfort / 5) * 100,
                }
            ];
        }
    }

    async showMore() {
        const nextLink = `${environment.api.staglCore}reviews/${this.clinic.slug}?${encodeURI(`page[number]=${this.currentIndex}&page[size]=15`)}`;
        const res: any = await this.clinicService.getPagingReviews(this.clinic.id as any, nextLink).toPromise();
        if (res) {
            res.reviews.forEach((row: any) => {
                row['useful'] = row['usefulness'];
                row['comment'] = row['review_message'];
                row['date'] = row['appointment_date'];
            });

            if (res.meta.page >= Math.ceil(res.meta.total / res.meta.rows_per_page)) {
                this.isAllReviewsLoaded = true;
            }
            this.currentIndex += 1;
            this.latestReviews = [...[], ...this.latestReviews, ...res.reviews];
            this.latestReviews = this.latestReviews.map((data: any) => {
                if (data && data.rating) {
                  const { rating } = data;
                  const score: any[] = [];
                  Object.keys(rating).forEach(function (key) {
                    score.push(rating[key]);
                  });
                  data.averageScore = ((score.reduce((a, b) => a + b, 0)) / score.length) || 0;
                }
                return data;
              });
        }
    }

}
