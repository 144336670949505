import { Component, EventEmitter, Input, isDevMode, Output } from '@angular/core';
import { PARTNERSHIP_FORM_ENUM } from '@lib/shared-core/components/partner-signup-core/partner-signup-core.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IpService } from '@lib/features/geolocation/ip.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IIpInfo } from '@lib/features/geolocation/ip.models';
import { RecaptchaActions } from '@lib/features/recaptcha/recaptcha.models';
import { RecaptchaService } from '@lib/features/recaptcha/recaptcha.service';
import { PartnerService } from '../../../../../../../dental/src/app/modules/partner/partner.service';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-opt-out-form',
  templateUrl: './opt-out-form.component.html',
  styleUrls: ['../../../components/partner-signup-core/partner-signup-core.component.scss',
    './opt-out-form.component.scss']
})
export class OptOutFormComponent {
  @Input() siteName!: string;
  @Output() partnerFormState: EventEmitter<PARTNERSHIP_FORM_ENUM> = new EventEmitter();

  form: UntypedFormGroup;
  separateDialCode = false;
  selectedCountryISO: CountryISO = CountryISO.UnitedStates;
  preferredCountries: Array<string> = [
    CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Canada, CountryISO.Australia, CountryISO.France
  ];
  protected readonly SearchCountryField = SearchCountryField;
  protected readonly PhoneNumberFormat = PhoneNumberFormat;

  private defaultValue = {
    clinic_name: 'Smart Dentals',
    why_opt_out: 'why opt out right here',
    how_can_we_improve: 'how can we improve right here',
    clinic_email: 'smartdental@doe.com',
    clinic_phone: '18040123'
  };
   processing!: boolean;


  constructor(
    private fb: UntypedFormBuilder,
    private ipService: IpService,
    private recaptchaService: RecaptchaService,
    private partnerService: PartnerService,
    private messageService: MessageService,
  ) {

    if (!isDevMode()) {
      this.defaultValue = {
        clinic_name: '',
        why_opt_out: '',
        how_can_we_improve: '',
        clinic_email: '',
        clinic_phone: ''
      };
    }

    this.form = this.fb.group({
      clinic_name: [this.defaultValue.clinic_name, [Validators.required,
        Validators.minLength(2), Validators.maxLength(200)]],

      clinic_email: [this.defaultValue.clinic_email, [Validators.required, Validators.email]],
      clinic_phone: [this.defaultValue.clinic_phone, Validators.required],

      why_opt_out: [this.defaultValue.why_opt_out, [Validators.required,
        Validators.minLength(5), Validators.maxLength(300)]],

      how_can_we_improve: [this.defaultValue.how_can_we_improve, [Validators.required,
        Validators.minLength(5), Validators.maxLength(300)]],
    });

    this.ipService.ipGeoInfo$.pipe(untilDestroyed(this)).subscribe((data: IIpInfo | null) => {
      if (data !== null) {
        this.selectedCountryISO = data?.countryCode ? data?.countryCode.toLocaleLowerCase() as any : this.selectedCountryISO;
      }
    });
  }

  submit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }
    this.processing = true;
    this.form.value.clinic_phone = this.form.value.clinic_phone?.e164Number


    this.recaptchaService.generateToken(RecaptchaActions.PartnerOptOut)
      .pipe(untilDestroyed(this))
      .subscribe((token: string) => {
        (this.form.value as any)['g-recaptcha-response'] = token;
        this.partnerService.optOut(this.form.value ).pipe(
          finalize(() => {
            this.processing = false;
          })
        )
          .subscribe(
          res => {
            this.partnerFormState.emit(PARTNERSHIP_FORM_ENUM.OPT_OUT_THANK_YOU)
          },
          err => {
            alert('Opt Out was Unsuccessful');
          }
        );
      });

  }
}
