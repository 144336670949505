import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FOOTER_DISPLAY_STATE,
  HEADER_DISPLAY_STATE,
  PageLayoutService
} from '@lib/features/page-layout/page-layout.service';
import { PARTNERSHIP_FORM_ENUM } from '@lib/shared-core/components/partner-signup-core/partner-signup-core.component';

@Component({
  selector: 'app-partner-opt-out-core',
  templateUrl: './partner-opt-out-core.component.html',
  styleUrls: ['./partner-opt-out-core.component.scss']
})
export class PartnerOptOutCoreComponent implements OnInit, OnDestroy {
  @Input() siteName!: string;

  partnershipFormState: PARTNERSHIP_FORM_ENUM = PARTNERSHIP_FORM_ENUM.OPT_OUT_FORM;

  partnershipFormEnum = PARTNERSHIP_FORM_ENUM;

  isSubmitted = false;


  constructor(private pageLayoutService: PageLayoutService) {}

  ngOnInit() {
    this.pageLayoutService.setToggleHeader(HEADER_DISPLAY_STATE.HIDE_HEADER);
    this.pageLayoutService.setToggleFooter(FOOTER_DISPLAY_STATE.HIDE_FOOTER);
  }

  changeFormState(state: PARTNERSHIP_FORM_ENUM) {
    this.partnershipFormState = state;
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.pageLayoutService.setToggleHeader(HEADER_DISPLAY_STATE.SHOW_HEADER);
    this.pageLayoutService.setToggleFooter(FOOTER_DISPLAY_STATE.SHOW_FOOTER);
  }

}
