import { Component, OnInit, Input, Injector, OnDestroy } from '@angular/core';
import { IUserProfile } from '@lib/auth/auth.model';
import { AuthService } from '@lib/auth/auth.service';
import { CommonService } from '@lib/common/common.service';
import { DENTAL_TREATMENTS } from '@lib/constants/dental-treatments';
import { LanguageService } from '@lib/features/language/language.service';
import { LogService } from '@lib/features/log/log.service';
import { I18nService } from '@lib/i18n/i18n.service';
import { MenuItem } from '@lib/models/menu-item.model';
import { sortMenuItemsLabel } from '@lib/utils/sortMenuItemsLabel';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, of } from 'rxjs';
import { ICommon, ICurrency, ILanguage } from '../../../../../core/src/lib/common/common.models';
import { MenuItem as MenuItemPrime } from 'primeng/api/menuitem';
import { HeaderDataItems, HeaderLogo } from '@lib/shared-core/layouts/header-v2/header-v2.component';
import { CurrencyService } from '@lib/features/currency/currency.service';
import { ApiService } from '@lib/data/core-api.service';
import { Router } from '@angular/router';
import { AccountAction, ShowState } from '@lib/models/account.models';
import { AccountService } from '@lib/features/account/account.service';
import { FavoriteService } from '@lib/features/favorite/favorite.service';
import { IpService } from '@lib/features/geolocation/ip.service';
import { catchError, map, take } from 'rxjs/operators';
import { UserActivityService } from '@lib/features/user-activity/user-activity.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  accountModalState = ShowState;

  private treatments: ReadonlyArray<any> = DENTAL_TREATMENTS;
  private platform: any = 'dental';


  @Input() common?: ICommon;
  @Input()
  logoImages: HeaderLogo = {
    desktopUrl: 'https://img.dentaldepartures.com/logo/dd_logo.svg',
    mobileUrl: 'https://img.dentaldepartures.com/logo-mini.png'
  };

  menuList: MenuItem[] = [];
  currentLanguage: ILanguage | undefined;
  languageLists: ILanguage[] = [];
  currentUser: IUserProfile | undefined;
  menuPrimeNgLists: MenuItemPrime[] = [];
  currencies: ICurrency[] = [];
  headerDataItems: HeaderDataItems | undefined;
  accountActions = AccountAction;

  constructor(
    private logService: LogService,
    private i18nService: I18nService,
    private commonService: CommonService,
    private languageService: LanguageService,
    private authService: AuthService,
    private currencyService: CurrencyService,
    private apiService: ApiService,
    private router: Router,
    private favoriteService: FavoriteService,
    public accountService: AccountService,
    private ipService: IpService,
    private userActivityService: UserActivityService
  ) { }



  ngOnInit() {

    this.getLocationUpdates(1);

    const commonHeaderItems$ = combineLatest([
      this.commonService.common$,
      this.languageService.currentLocale,
      this.authService.currentUser,
      this.currencyService.currentCurrency,
      this.favoriteService.Favorites
    ]);

    commonHeaderItems$.pipe(untilDestroyed(this)).subscribe((data: any | any[]) => {

      const [common, currentLanguage, currentUser, currentCurrency] = data;

      if (common) {

        if (!common.currencies) { throw new Error('No common currencies'); }
        if (!common.languages) { this.logService.warn('No common languages'); }
        if (!common.locations) { throw new Error('No common locations'); }
        this.menuList = this.mapMenuNavigator(common);
        this.menuPrimeNgLists = this.mapMenuItemsToPrimeNg(this.menuList);

        let { currencies } = common;
        if (currencies) {
          currencies = currencies.map((c: any) => this.translateCurrencyName(c));
          this.currencies = currencies;
          const clinicCurrency: any = {
            'name': $localize`:@@clinicCurrency:Clinic's Currency`,
            "symbol": "Clinic's Currency (£€$)",
            "code": "£€$"
          };
          this.currencies = [...[clinicCurrency], ...this.currencies];
        }

      }

      if (currentLanguage) {

        this.languageLists = this.languageService.getAvailableLanguages();
        this.currentLanguage = this.languageLists.find(row => row.code === currentLanguage.code)!;
        this.languageService.sortLanguages(this.languageLists, this.currentLanguage);
        this.menuPrimeNgLists = [...this.menuPrimeNgLists, ...this.mapLanguageItemsToPrimeNg(this.currentLanguage, this.languageLists)];

      }

      if (currentUser) {
        this.currentUser = currentUser;
      }

      if (this.currencies) {
        const [currentValueCurrency] = this.currencies.filter(data => data.code == currentCurrency);
        this.menuPrimeNgLists = [...this.menuPrimeNgLists, ...this.mapCurrencyItemsToPrimeNg(currentValueCurrency, this.currencies)];
      }

      this.menuPrimeNgLists = [...this.menuPrimeNgLists, ...this.createUserMenuPrimeNg(currentUser)];

      this.headerDataItems = {
        menuPrimeNgLists: this.menuPrimeNgLists,
        languageLists: this.languageLists,
        currentLanguage: this.currentLanguage,
        currentUser: this.currentUser,
        platform: this.platform
      };

    });

    this.authService.currentUser.pipe((untilDestroyed(this))).subscribe((data) => {
      if (data) {
        this.favoriteService.fetchUserFavorites().pipe(untilDestroyed(this)).subscribe((favorites) => {
          if (favorites) {
            this.favoriteService.setFavorites(favorites);
          }
        })
      }
    });

  }

   /**
 *
 * @param limit added take limit on first load and uses date to avoid caching geolocation
 *
 */
   getLocationUpdates(limit: number) {
    const $locationGet = this.apiService.geolocationGET(limit >= 1);
    if(limit) {
      const commonIpItems$ = combineLatest([
        $locationGet.pipe(take(limit),catchError(() => of(null))),
        this.userActivityService.IPInfo
      ]);
      commonIpItems$.subscribe((data : any)=> {
        const [ ipServerInfo, ipInfo ]  = data;
        if(ipInfo!=null) {
          const mockServerInfo = {
            'ip': ipInfo.ip,
            'country': ipInfo.country,
            'countryCode': ipInfo.country_code,
            'callingCode': ipInfo.country_calling_code,
            'currency': ipInfo.currency
          };
          this.currencyService.updateDefaultCurrency(mockServerInfo.countryCode);
          this.ipService.updateGeoTargetIP(mockServerInfo);
        } else {
            if(ipServerInfo !=null ) {
              this.currencyService.updateDefaultCurrency(ipServerInfo.countryCode);
              this.ipService.updateGeoTargetIP(ipServerInfo);
            }
            else {
                const usMockData = {
                  'ip': '',
                  'country': 'United States of America',
                  'countryCode': 'US',
                  'callingCode': '1'
                };
                this.currencyService.updateDefaultCurrency(usMockData.countryCode);
                this.ipService.updateGeoTargetIP(usMockData);
              }
          }
      });
    } else {
      $locationGet.pipe(untilDestroyed(this)).subscribe((data) => {
        this.currencyService.updateDefaultCurrency(data.countryCode);
        this.ipService.updateGeoTargetIP(data);
      });
    }
  }

  /**
   *
   * @param currency pass currency code to change the current currency
   */
  changeCurrency(currency: string) {
    this.currencyService.changeCurrency(currency);
  }

  /**
   *
   * @param currency pass currency code to change the current currency
   */
  changeCurrentLanguage(item: ILanguage) {
    this.languageService.changeLanguage(item.path);
  }

  /**
   *
   * @param currency pass currency code to change the current currency
   */
  openLoginModal() {
    this.accountService.updateCurrentAccountStateModal(this.accountActions.LOGIN)
  }


  /**
   *
   * @param user current user login
   * @returns
   */
  createUserMenuPrimeNg(user: IUserProfile): MenuItemPrime[] {

    const loginLink = this.authService.generateLogInLink(this.router.url);
    const logoutLink = this.authService.generateLogOutLink(this.router.url);
    const editProfileLink = this.authService.generateAccountLink() + '/profile';

    if (user) {
      const username = user.first_name + ' ' + user.last_name;
      return [{
        label: `${username.trim() ? username : user.email}`,
        icon: 'fas fa-caret-down',
        styleClass: 'user-wrapper',
        routerLink: '',
        visible: false,
        items: [
          { label: 'Edit Profile', url: editProfileLink },
          {
            label: 'Logout', command: () => {
              this.authService.logout();
            }
          },
        ]
      }];
    } else {
      return [{
        label: '',
        styleClass: 'user-wrapper',
        icon: 'ri-user-fill',
        visible: false,
        command: () => {
          this.openLoginModal();
        }
      }];
    }
  }

  private translateCurrencyName = (currency: ICurrency): ICurrency => {
    if (!this.i18nService.isGlobalSite()) {
      if (this.i18nService.getCurrentLocale() === 'th') {
        currency.name = this.getTranslatedCurrencyName(currency, this.currencyService.currenciesTh);
      }
      if (this.i18nService.getCurrentLocale() === 'es') {
        currency.name = this.getTranslatedCurrencyName(currency, this.currencyService.currenciesEs);
      }
      if (this.i18nService.getCurrentLocale() === 'de') {
        currency.name = this.getTranslatedCurrencyName(currency, this.currencyService.currenciesDe);
      }
      if (this.i18nService.getCurrentLocale() === 'fr') {
        currency.name = this.getTranslatedCurrencyName(currency, this.currencyService.currenciesFr);
      }
    }
    return currency;
  }

  private getTranslatedCurrencyName = (currency: ICurrency, currencies: { code: string, name: string }[]): string => {
    const foundCurrency = currencies.find(c => c.code === currency.code);
    return (foundCurrency) ? foundCurrency.name : currency.name;
  }


  /**
   *
   * @param common pass current data from common subscription
   * @returns list of menu lists
   */
  mapMenuNavigator(common: ICommon | any): MenuItem[] {

    let menuLists: MenuItem[] = [];
    const premiumContentLinkPrefix = this.i18nService.isGlobalSite() ? '/info' : '/dentists';
    const targetTreatmentLocation = (this.i18nService.locale === 'th') ? '/thailand' : '';

    menuLists = [
      { ...MenuItem.createInternalLink($localize`:@@contact:Contact`, '/contact'), ...{ styleClass: 'contact-wrapper' } },
      new MenuItem(
        $localize`:@@locations:Locations`,
        '/locations',
        false,
        '',
        '',
        'fas fa-caret-down',
        [].concat.apply([], common.locations
          .map((country: any) => MenuItem.createInternalLinkWithIconAndChildren(country.name,
            `${premiumContentLinkPrefix}/treatments/${country.slug}`,
            'fas fa-caret-right',
            (country.children && country.children[0])
              ? [].concat.apply([], country.children.map((province: any) =>
                (province)
                  ? (province.children && province.children.length > 0)
                    ? province.children.map((city: any) => (city) ? MenuItem.createInternalLinkWithChildren(city.name, `${premiumContentLinkPrefix}/treatments/${country.slug}/${province.slug}/${city.slug}`) : '').sort(sortMenuItemsLabel)
                    : MenuItem.createInternalLinkWithChildren(province.name, `${premiumContentLinkPrefix}/treatments/${country.slug}/${province.slug}`)
                  : '')).sort(sortMenuItemsLabel)
              : [])).sort(sortMenuItemsLabel)
          .concat([
            MenuItem.createInternalLink('Covid-19 Certified Clinics', '/group/covid-trust-shield'),
            MenuItem.createInternalLink($localize`:@@moreLocations:More Locations`, '/locations')
          ]))),
      MenuItem.createInternalLinkWithCustomCssClass(`About us`, '/about', 'about-wrapper'),
      MenuItem.createInternalLinkWithIconAndChildren(
        $localize`:@@treatments:Treatments`,
        '/treatments',
        'fas fa-caret-down',
        [].concat.apply([], (this.treatments as any)
          .map((treatment: any) => MenuItem.createInternalLinkWithChildren(treatment.name, `${premiumContentLinkPrefix}/${treatment.slug}${targetTreatmentLocation}`))
          .concat([MenuItem.createInternalLinkWithChildren($localize`:@@moreTreatments:More Treatments`, '/treatments')]))),
      MenuItem.createInternalLinkWithIconAndChildren(
        $localize`:@@more:More`,
        '',
        'fas fa-caret-down more-wrapper',
        [
          MenuItem.createInternalLinkWithCustomCssClass($localize`:@@freeQuote:Free Quote`, '/free-quote', 'nav__item--free-quote'),
          MenuItem.createExternalLink($localize`:@@rewards:Rewards`, 'http://book.medicaldepartures.com/medical_departures_rewards/'),
          MenuItem.createInternalLink($localize`:@@bestPriceGuarantee:Best Price Guarantee`, '/guarantee'),
          MenuItem.createInternalLink($localize`:@@treatmentFinance:Treatment Finance`, '/finance'),
          // MenuItem.createInternalLink($localize`:@@treatmentInsurance:Treatment Insurance`, '/insurance'),
          MenuItem.createInternalLink($localize`:@@promotions:Promotions`, '/promotions'),
          MenuItem.createInternalLinkWithIconAndChildren(
            $localize`:@@articles:Articles`,
            '/articles',
            'fas fa-caret-right',
            [
              MenuItem.createInternalLink($localize`:@@destinationsArticles:Destinations`, '/articles/destinations'),
              MenuItem.createInternalLink($localize`:@@resourcesArticles:Resources`, '/articles/resources'),
              MenuItem.createInternalLink($localize`:@@proceduresArticles:Procedures`, '/articles/procedures')
            ]),
          MenuItem.createInternalLink($localize`:@@mediaRoom:Media Room`, '/media-room'),
          MenuItem.createInternalLink($localize`:@@press:Press`, '/press'),
          MenuItem.createInternalLink($localize`:@@sitemaps:Sitemap`, '/sitemap')
        ]
      )
    ];

    if (!this.i18nService.isGlobalSite()) {
      menuLists[4].children.splice(menuLists[4].children.findIndex(c => c.link === '/articles'), 1);
      menuLists[4].children.splice(menuLists[4].children.findIndex(c => c.link === '/insurance'), 1);
      menuLists[4].children.splice(menuLists[4].children.findIndex(c => c.link === '/finance'), 1);
    }
    return menuLists;

  }



  /**
   *
   * @param data pass menu item, perform recursive call for child items to be mapped
   * @returns menu items for primeng
   */
  mapLanguageItemsToPrimeNg(currentLanguage: ILanguage, data: ILanguage[] | any[] | any): MenuItemPrime[] {
    const items = data.map((item: ILanguage) => ({
      label: item.value,
      icon: `flag-icon flag-icon-${item.isoAlpha2Code.toLowerCase()}`,
      path: item.path,
      command: () => {
        this.changeCurrentLanguage(item);
      }
    })).filter((data: any) => data.label != 'Turkey');
    return [{
      label: currentLanguage.value,
      icon: `flag-icon flag-icon-${currentLanguage.isoAlpha2Code.toLowerCase()}`,
      styleClass: 'language-wrapper',
      visible: false,
      items
    }];
  }


  /**
   *
   * @param data pass menu item, perform recursive call for child items to be mapped
   * @returns menu items for primeng
   */
  mapMenuItemsToPrimeNg(data: MenuItem[] | any[] | any): MenuItemPrime[] {
    return data.map((item: MenuItem) => ({
      label: item.label,
      icon: item.icon,
      routerLink: !item.isExternalLink ? item.link : undefined,
      visible: true,
      url: item.isExternalLink ? item.link : undefined,
      items: item.children && item.children.length > 0 ? this.mapMenuItemsToPrimeNg(item.children) : null
    }));
  }


  /**
   *
   * @param currentCurrency current currency to be default on menu
   * @param data currency list
   * @returns
   */
  mapCurrencyItemsToPrimeNg(currentCurrency: ICurrency, data: ICurrency[] | any[] | any): MenuItemPrime[] {
    const items = data.map((item: ICurrency) => ({
      label: item.code,
      icon: item.symbol,
      code: item.code,
      command: () => {
        this.changeCurrency(item.code);
      }
    }));
    return [{
      label: currentCurrency ? currentCurrency.code : '',
      icon: 'fas fa-caret-down',
      styleClass: 'currency-wrapper',
      visible: false,
      items
    }];
  }


}
