<section class="thank-you">
  <img src="assets/icons/sad-face.svg" alt="sad face icon">

  <h1 i18n="@@optOutThankYouTitle">Termination of Partnership Process Initiated</h1>

  <p  i18n="@@optOutThankYouSubTitle">
    We hate goodbyes, but if you change your mind, we will always be here.
    Please allow up to 3 business days for your request to be processed.
  </p>

  <button (click)="goto()" pButton  i18n="@@goBackHome"> Go back to Homepage</button>
  <br/><br/>
</section>
