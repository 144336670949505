<div class="clinic-panel-wrapper" *ngIf="isLoading">
    <div class="clinic-image-wrapper">
        <p-skeleton [height]="'100%'"></p-skeleton>
    </div>
    <h1><p-skeleton [height]="'20px'" [width]="'40%'"></p-skeleton></h1>
    <div class="basic-info-clinic">
        <p-skeleton [height]="'100%'"></p-skeleton>
    </div>
    <div class="grid-gallery">
        <div class="parent">
            <ng-container *ngFor="let photos of [].constructor(5); let i = index">
                <div class='pic{{i + 1}}'>
                    <p-skeleton [height]="'100%'"></p-skeleton>
                </div>
            </ng-container>
        </div>

    </div>
    <div class="row body-section">
        <div class="col-12 col-md-8">
            <h1> <p-skeleton></p-skeleton></h1>
            <p> <p-skeleton></p-skeleton></p>
            <h1> <p-skeleton></p-skeleton></h1>

        </div>
        <div class="col-12 col-md-4">
            <div>
                <br />
                <div class="title">
                    <p-skeleton [width]="'40%'"></p-skeleton>
                </div><br />
                <p-skeleton></p-skeleton><br />
                <p-skeleton></p-skeleton><br />
            </div>
        </div>
        <div class="col-12">
            <br />
            <p-skeleton></p-skeleton>
        </div>
        <div class="col-12">
            <br />
            <p-skeleton [width]="'40%'"></p-skeleton>
            <br />
        </div>
        <div class="col-12 col-md-4">
            <div>
                <br />
                <div class="title">
                    <p-skeleton [width]="'40%'"></p-skeleton>
                </div><br />
                <p-skeleton></p-skeleton><br />
                <p-skeleton></p-skeleton><br />
            </div>
        </div>
        <div class="col-12">
            <br />
            <p-skeleton></p-skeleton>
        </div>
        <div class="col-12">
            <br />
            <p-skeleton [width]="'40%'"></p-skeleton>
            <br />
        </div>
        <div class="col-12 col-md-4">
            <div>
                <br />
                <div class="title">
                    <p-skeleton [width]="'40%'"></p-skeleton>
                </div><br />
                <p-skeleton></p-skeleton><br />
                <p-skeleton></p-skeleton><br />
            </div>
        </div>
        <div class="col-12">
            <br />
            <p-skeleton></p-skeleton>
        </div>
        <div class="col-12">
            <br />
            <p-skeleton [width]="'40%'"></p-skeleton>
            <br />
        </div>
    </div>
</div>


<div class="clinic-panel-wrapper" *ngIf="clinic && !isLoading">
    <div class="clinic-image-wrapper">
        <span class="premium-mobile" *ngIf="clinic.clinic_type == 'premium'" [pTooltip]="clinicInfoToolTip"
            tooltipStyleClass="grid-style-tooltip" autoHide="false" [escape]="false">
            <span i18n="@@premium">PREMIUM</span></span>
        <div class="other-buttons">
            <span class="share" (click)="isMobileNative ? navigatorShare() : openModal(clinicModalViews.share)">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.2695 8V12.5C12.2695 12.7652 12.1642 13.0196 11.9766 13.2071C11.7891 13.3946 11.5347 13.5 11.2695 13.5H2.26953C2.00431 13.5 1.74996 13.3946 1.56242 13.2071C1.37489 13.0196 1.26953 12.7652 1.26953 12.5V8M6.76953 0.5V12M1.76953 5.5L6.41953 0.85C6.513 0.758387 6.63865 0.707071 6.76953 0.707071C6.90041 0.707071 7.02607 0.758387 7.11953 0.85L11.7695 5.5"
                        stroke="#222222" />
                </svg></span>
            <span class="favorite-mobile">
                <app-favorite-core [isFavorite]="isFavorite" [currentUser]="authService.currentUser | async"
                    [hasLabel]="true"
                    [favoriteClinicCore]="{ favorites: ( favoriteService.Favorites | async ), clinicData: clinic }"></app-favorite-core>
            </span>
        </div>
        <div class="carousel">
            <app-carousel [hasTotalSlide]="true" [dataCarousel]="{type: 'photo-modal-only', data: clinicMainPhotos} "
                [showNavigators]="true" (click)="$event.stopPropagation()" [showIndicators]="false"
                (onClickEvent)="onClickImageGallery($event)"
                [customWrapper]="'card card-search-wrapper premium-content-card'">
            </app-carousel>
        </div>
    </div>
    <div class="inline-display">
      <h1>{{clinic.name}}
      </h1>
      <span class="mobile-only" *ngIf="clinic.clinic_type == 'premium'" [pTooltip]="clinicInfoToolTipMobile"
              tooltipStyleClass="grid-style-tooltip" [escape]="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                  <path
                      d="M17.25 8L15.42 5.9075L15.675 3.14L12.9675 2.525L11.55 0.125L9 1.22L6.45 0.125L5.0325 2.5175L2.325 3.125L2.58 5.9L0.75 8L2.58 10.0925L2.325 12.8675L5.0325 13.4825L6.45 15.875L9 14.7725L11.55 15.8675L12.9675 13.475L15.675 12.86L15.42 10.0925L17.25 8ZM7.5675 11.54L4.7175 8.6825L5.8275 7.5725L7.5675 9.32L11.955 4.9175L13.065 6.0275L7.5675 11.54Z"
                      fill="#0288BD" />
              </svg></span>
    </div>
    <div class="basic-info-clinic">
        <i class="fa fa-star"></i> <span class="rating-average">{{clinic.reviews.rating | number: '1.2-2'}}</span>
        <span class="rating-reviews">{{ clinic.reviews.reviews }} <span i18n="@@reviews"> reviews </span></span>
        <ng-container *ngIf="clinic.clinic_type == 'premium'">
            <div class="premium" [pTooltip]="clinicInfoToolTip" tooltipStyleClass="grid-style-tooltip" [escape]="false"
                [appendTo]="premiumContainer" tooltipPosition="bottom">
                <div #premiumContainer></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="19" viewBox="0 0 9 19" fill="none">
                    <g clip-path="url(#clip0_6803_3358)">
                        <path
                            d="M7.52666 7.12504C7.66388 7.01949 7.73249 6.8981 7.73249 6.76087V4.33837C7.73249 4.29615 7.71138 4.25921 7.66916 4.22754C7.62694 4.19587 7.57944 4.18004 7.52666 4.18004H0.939995C0.887217 4.18004 0.839717 4.19587 0.797495 4.22754C0.755272 4.25921 0.734161 4.29615 0.734161 4.33837V6.76087C0.734161 6.8981 0.802772 7.01949 0.939995 7.12504L3.88499 9.61087C3.99055 9.67421 4.10666 9.70587 4.23333 9.70587C4.37055 9.70587 4.48666 9.67421 4.58166 9.61087L7.52666 7.12504ZM4.23333 15.8334C3.74777 15.8334 3.3018 15.7146 2.89541 15.4771C2.48902 15.2396 2.16708 14.9177 1.92958 14.5113C1.69208 14.1049 1.57333 13.6589 1.57333 13.1734C1.57333 12.6878 1.69208 12.2418 1.92958 11.8355C2.16708 11.4291 2.48902 11.1045 2.89541 10.8617C3.3018 10.6189 3.74777 10.4975 4.23333 10.4975C4.71888 10.4975 5.16486 10.6189 5.57124 10.8617C5.97763 11.1045 6.29958 11.4291 6.53708 11.8355C6.77458 12.2418 6.89333 12.6878 6.89333 13.1734C6.89333 13.6589 6.77458 14.1049 6.53708 14.5113C6.29958 14.9177 5.97763 15.2396 5.57124 15.4771C5.16486 15.7146 4.71888 15.8334 4.23333 15.8334Z"
                            fill="#222222" />
                    </g>
                    <defs>
                        <clipPath id="clip0_6803_3358">
                            <rect width="7.72" height="19" fill="white" transform="matrix(1 0 0 -1 0.690002 19)" />
                        </clipPath>
                    </defs>
                </svg>
                <span i18n="@@premium">
                    Premium
                </span>
            </div>

        </ng-container>
        <span class="address">
            <ng-container *ngIf="clinic.location && clinic.location.city && clinic.location.city.name">
                {{clinic.location.city.name}} , </ng-container>
            <ng-container *ngIf="clinic.location && clinic.location.country && clinic.location.country.name">
                {{clinic.location.country.name}} </ng-container>
        </span>
        <div class="other-buttons">
            <span class="share" (click)="isMobileNative ? navigatorShare() : openModal(clinicModalViews.share)">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.2695 8V12.5C12.2695 12.7652 12.1642 13.0196 11.9766 13.2071C11.7891 13.3946 11.5347 13.5 11.2695 13.5H2.26953C2.00431 13.5 1.74996 13.3946 1.56242 13.2071C1.37489 13.0196 1.26953 12.7652 1.26953 12.5V8M6.76953 0.5V12M1.76953 5.5L6.41953 0.85C6.513 0.758387 6.63865 0.707071 6.76953 0.707071C6.90041 0.707071 7.02607 0.758387 7.11953 0.85L11.7695 5.5"
                        stroke="#222222" />
                </svg>&nbsp; <span i18n="@@share">Share</span>
            </span>
            <span class="favorite">
                <app-favorite-core [isFavorite]="isFavorite" [currentUser]="authService.currentUser | async"
                    [hasLabel]="true"
                    [favoriteClinicCore]="{ favorites: ( favoriteService.Favorites | async ), clinicData: clinic }"></app-favorite-core>
            </span>
        </div>
    </div>
    <div class="grid-gallery">
        <span class="premium" *ngIf="clinic.clinic_type == 'premium'" [pTooltip]="clinicInfoToolTip"
            tooltipStyleClass="grid-style-tooltip" autoHide="false" [escape]="false">
            <span i18n="@@premium">PREMIUM</span></span>
        <div class="parent">
            <ng-container *ngFor="let photos of clinicMainPhotos.slice(0, 5); let i = index">
                <div class='pic{{i + 1}}' style="background-image: url({{photos.image}});"
                    (click)="onClickImageGallery(i)"></div>
            </ng-container>
        </div>
        <span class="show-all" *ngIf="clinicMainPhotos" (click)="onClickImageGallery(0)"><i
                class="ri-grid-fill"></i>&nbsp;
            <span i18n="@@showAllPhotos"> Show all photos </span></span>
    </div>
    <div class="row body-section">
        <div class="col-12 col-md-8">
            <h1 i18n="@@aboutClinic">About {{clinic.name}}</h1>
            <p>{{clinic.info.about_short}}</p>
            <p [class.isShowMore]="isShowMore" [class.isHideMore]="!isShowMore" [innerHTML]="clinic.info.about"></p>
            <p class="show-more" (click)="isShowMore = !isShowMore">
                <i class="ri-arrow-left-s-line" *ngIf="isShowMore"></i>
                {{!isShowMore ? more : less}}
                <i class="ri-arrow-right-s-line" *ngIf="!isShowMore"></i>
            </p>
            <hr />
            <h1 i18n="@@pricesAndProcedures">Prices and Procedures</h1>
            <button pButton i18n="@@getQuote"
                class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"
                (click)="openModal(clinicModalViews.getQuote);">Get Quote</button>
            <br />
            <ng-container *ngIf="clinicPrices">
                <app-prices [clinicPrices]="clinicPrices" [currency]="clinic.currency"
                    [pricesColumn]="pricesColumn"></app-prices>
            </ng-container>
            <br />
            <button pButton i18n="@@contactClinic"
                class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"
                (click)="openModal(clinicModalViews.contactClinic);">Contact clinic</button><br />
        </div>
        <div class="col-12 col-md-4">
            <div class="card-clinic-float">
                <div class="title" i18n="@@saveOnYourNewSmile">
                  Save Up to 70% on Your New Smile
                </div><br />
                <button pButton class="wb-100 p-button-label-zero p-button" i18n="@@bookAppointment"
                    (click)="bookClinic()">Book appointment</button>
                <br />
                <button pButton i18n="@@contactClinic"
                    class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"
                    (click)="openModal(clinicModalViews.contactClinic);">Contact clinic</button>
                <br />
            </div>
        </div>
        <div class="col-12" *ngIf="clinic.promotions && clinic.promotions.length > 0">
            <hr />
            <app-promotions [promotions]="clinic.promotions" [hasPromotionButton]="false"></app-promotions>
        </div>

        <div class="col-12" *ngIf="clinic.reviews">
            <hr />
            <app-reviews-popup [clinic]="clinic" [clinicReview]="clinic.reviews"
                (onClickShowMore)="openModal(clinicModalViews.reviewModal)"
                (onClickWriteAReview)="openModal(clinicModalViews.commentReviewModal)"
                [latestReviews]="clinic.reviews && clinic.reviews.list ? clinic.reviews.list : [] "></app-reviews-popup>
        </div>

        <div class="col-12" *ngIf="clinic.doctors">
            <hr />
            <app-doctors [clinicDoctors]="clinic.doctors"></app-doctors>
        </div>

        <div class="col-12" *ngIf="clinic.hotels && clinic.hotels.length > 0">
            <hr />
            <app-hotels [hotels]="clinic.hotels"></app-hotels>
        </div>
        <div class="col-12">
            <app-about-clinic [clinic]="clinic" [hasAboutUs]="false">
            </app-about-clinic>
        </div>
        <div class="col-12" *ngIf="clinic.location">
            <hr />
            <app-map-iframe [name]="clinic.name" [latlong]="clinic.location.google_map"
                [address]="clinic.location.address">
            </app-map-iframe>
            <br />
        </div>
    </div>
</div>

<app-image-gallery [activeIndex]="currentGalleryIndex" [images]="clinicMainPhotos"
    (onChangeVisible)="onChangeVisible($event)" [isVisible]="visibleGallery"></app-image-gallery>


<p-dialog [(visible)]="clinic && isModalVisible" [modal]="true" [showHeader]="false" class="custom-clinic-dialog"
    (onHide)="onToggleVisible(true)" (onShow)="onToggleVisible(false)" [position]="'center'">
    <div class="close-action"><i class="ri-close-fill" (click)="isModalVisible=false;"></i></div>
    <div class="custom-clinic-dialog-wrapper"
        [class.comment-review-modal]="currentModalContent === clinicModalViews.commentReviewModal">
        <div class="close-action-pusher"></div>
        <div [ngSwitch]="currentModalContent">
            <ng-container *ngSwitchCase="clinicModalViews.contactClinic">
                <app-contact-core [clinic]="clinic"></app-contact-core>
            </ng-container>
            <ng-container *ngSwitchCase="clinicModalViews.commentReviewModal">
                <app-comment-review [clinic]="clinic"></app-comment-review>
            </ng-container>
            <ng-container *ngSwitchCase="clinicModalViews.share">
                <app-share-profile [clinic]="clinic"></app-share-profile>
            </ng-container>
            <ng-container *ngSwitchCase="clinicModalViews.showMore">
                <app-about-clinic [clinic]="clinic">
                </app-about-clinic>
            </ng-container>
            <ng-container *ngSwitchCase="clinicModalViews.reviewModal">
                <app-reviews-popup [clinic]="clinic" [clinicReview]="clinic.reviews" [isReviewModal]="true"
                    [latestReviews]="clinic.reviews && clinic.reviews.list ? clinic.reviews.list : [] "></app-reviews-popup>
            </ng-container>
            <ng-container *ngSwitchCase="clinicModalViews.getQuote">
                <ng-container *ngIf="clinicPrices">
                    <app-prices [clinicID]="clinic.id" [clinicNewID]="clinic.info.new_clinic_id" [clinicPrices]="clinicPrices" [currency]="clinic.currency"
                        [hasPrivacy]="true" [isInModal]="true" [pricesColumn]="pricesColumnPopup" [allowSelect]="true">
                        <h1 i18n="@@whyListLabel5">Free Quote</h1>
                        <div [innerHTML]="clinic.info.about_short" class="cms-content"></div>
                        <div class="cms-content">
                            <ng-container i18n="@@clinicQuoteInfo">
                                <p>
                                    You can use our convenient calculator to get an estimated price on
                                    your dental care at <a routerLink="/dentist/{{ clinic.slug }}/"
                                        title="{{ clinic.name }}" class="notranslate">{{ clinic.name }}</a>.
                                </p>
                                <p *ngIf="smallestLocation">
                                    You can also use this tool to compare to other <a
                                        routerLink="/dentists/treatments/{{ staticSearchURL }}">{{
                                        smallestLocation.name }} clinic prices</a>
                                </p>
                                <p>
                                    If you have any other questions, you can <a id="connect-link"
                                        routerLink="/connect/">contact us</a> to request a personalized quote free of
                                    charge!
                                </p>
                            </ng-container>
                        </div>
                    </app-prices>
                </ng-container>
            </ng-container>
        </div>
    </div>
</p-dialog>
