<div class="how-to-wrapper" *ngIf="howTos">
    <h2 i18n="@@howDoIBook" class="title-only text-center" i18n="@@howDoIBook">How Do I Book?</h2>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <swiper-container customSwiper navigation="true" class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled"
                (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
                (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
                <swiper-slide *ngFor="let howTo of howTos">
                    <div class="swiper-slide swiper-options-header">
                        <div fxLayout="column">
                            <div class="card general-card">
                                <div class="inside-card">
                                    <div class="why__icon">
                                        <img [src]="'assets/icons/'+howTo.icon" [alt]="howTo.icon">
                                    </div>
                                    <h4>{{howTo.title}}</h4>
                                    <p *ngIf="!howTo.isEmail">{{howTo.info}}</p>
                                    <a *ngIf="howTo.isEmail" [href]="'mailto:'+howTo.link">{{howTo.info}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>
